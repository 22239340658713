body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Urbanist", "sans-serif";
}

.root {
  background-color: #f9f9f9;
  font-family: Urbanist, sans-serif;
}

.tableHeader {
  background-color: #b930e6;
  color: white;
  font-family: "Urbanist", "sans-serif";
  font-size: .9em
}

.tableHeader:last-child > .MuiDataGrid-columnSeparator {
  display: none;
}

.MuiDataGrid-root{
  border-radius: 8px !important;
}

.MuiDataGrid-columnHeaders{
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.navLink {
  font-weight: 800;
  color: #fff;
  text-decoration: none;
  margin-right: 18px;
  font-family: "Urbanist", "sans-serif";
  font-size: 18px;
  margin-bottom: 1px;
}

.navLinkMenu {
  color: #333;
  text-decoration: none;
  font-size: 12;
}

.hoverPointer {
  cursor: pointer;
}

.rowapproved {
  background-color: #26C6F5;
  color: #fff;
  font-weight:700;
}

.rowapproved:hover {
  background-color: #26C6F5 !important;
  color: #fff;
  font-weight:700;
}

.rowdenied{
  background-color: #C2310C;
  color:#fff;
  font-weight: 700;
}

.rowdenied:hover{
  background-color: #C2310C !important;
  color:#fff;
  font-weight: 700;
}

.rowcancelled{
  background-color: #ddd;
  color:#000;
  font-weight: 400;
}

.taken {
  background-color: #f6e649;
  border-radius: 4px;
  padding: 4px;
}

/* .MuiDataGrid-row:hover{
  background-color: #aaa !important;
} */
